import { createContext, useCallback, useContext, useReducer } from 'react';

import { ModalProps as ZolaModalProps } from '@zola/zola-ui/src/components/Modal/Modal';

import { createAction, createReducer } from '@reduxjs/toolkit';
import { OnAfterOpenCallback } from 'react-modal';

export type PredefinedModalV2 =
  | 'BOOKED_VENDORS_CONFIRMATION_MODAL'
  | 'BUDGET_RESOLUTION_MODAL'
  | 'CANCEL_PLAN'
  | 'CREDITS_NEEDED_MODAL'
  | 'COUPLES_CANCEL_INQUIRY_MODAL'
  | 'DELETE_SEARCH_CONFIRMATION'
  | 'EDIT_CATEGORY_MODAL'
  | 'EDIT_PAYMENT_METHOD_MODAL'
  | 'EMAIL_ADD_ON_MODAL'
  | 'FIRST_MOVE_EXPLANATION'
  | 'FIRST_MOVE_OPT_IN_MODAL'
  | 'HELLOS_OPT_IN_MODAL'
  | 'HELLOS_OPT_OUT_MODAL'
  | 'HELLOS_MESSAGE_MODAL'
  | 'HOW_WE_PRICE_MODAL'
  | 'INFO_MODAL'
  | 'INQUIRY_CONFIRMATION_MODAL'
  | 'INQUIRY_DETAIL_MODAL'
  | 'INQUIRY_MODAL'
  | 'INQUIRY_RESOLUTIONS_MODAL'
  | 'INSTANT_REGISTRY_START_MODAL'
  | 'INVITE_VENDOR_USER_MODAL'
  | 'LEARN_MORE_ABOUT_CREDIT_PLANS'
  | 'MARKETPLACE_AUTHV2'
  | 'MARK_BOOKED_MODAL'
  | 'MASS_INQUIRY_CONFIRMATION_MODAL'
  | 'MINI_QUIZ_MODAL'
  | 'MINI_QUIZ_PROMPT_MODAL'
  | 'PAPER_CROSS_SELL_PREVIEW'
  | 'PAPER_SAMPLE_CHECKOUT'
  | 'PRICING_HELP_MODAL'
  | 'PROCESSING_MODAL'
  | 'QUICK_CHECKOUT_MODAL'
  | 'QUICK_RESPONDER_MODAL'
  | 'REORDER_MODAL'
  | 'REPORT_SPAM_MODAL'
  | 'SAVED_SEARCHES'
  | 'SAVE_NEW_SEARCH'
  | 'SEARCH_CARD_QUICK_VIEW'
  | 'SELECT_VENDOR_TYPE'
  | 'SIMPLE_INFO_MODAL'
  | 'STOREFRONT_USER_ACCESS_LIST_MODAL'
  | 'TEXT_NOTIFICATIONS'
  | 'UNBOOK_VENDOR_V2'
  | 'UNCLAIMED_CLAIM_LISTING'
  | 'UNCLAIMED_LEARN_MORE'
  | 'UNSUPPLIED_MARKET'
  | 'VENDOR_CANCEL_INQUIRY_MODAL'
  | 'VENDOR_DETAILS'
  | 'VENDOR_FORGOT_PASSWORD'
  | 'VENDOR_LOGIN'
  | 'VENDOR_SIGNUP'
  | 'VIDEO_MODAL';

export interface ModalProps {
  hideClose?: boolean;
}

/**
 * Props that can be provided by the caller of showModal.  These are
 * - The props the component takes
 * - the optional field hideClose
 */
export type ModalPropsType<T> = Omit<T & ModalProps, 'hideModalFn'>;

/**
 * Options provided to the modal to control behavior
 */
export interface ModalOptions extends Record<string, unknown> {
  closeOnEsc?: boolean;
  closeOnOverlayClick?: boolean;
  size?: 'small' | 'lg' | 'xl';
  className?: string;
  onAfterOpen?: OnAfterOpenCallback;
  /**
   * A function that will run after the modal has closed
   */
  onAfterClose?: () => void;
  /**
   * The modal slides up, locks to the bottom, and only partially covers the screen on mobile
   */
  mobileHalfSheet?: boolean;
  isMarketplaceAuthModal?: boolean;
}

export type ModalV2Props = Pick<
  ZolaModalProps,
  | 'className'
  | 'closeOnEscape'
  | 'closeOnOverlayClick'
  | 'dialogHeadingId'
  | 'lockBgScrolling'
  | 'showCloseButton'
  | 'triggerRef'
  | 'disableFocusTrap'
> &
  (
    | {
        /**
         * Optional callback to invoke after the modal closes.  If you want to
         * intercept the modal being closed, use onClose to take full control.
         */
        onAfterClose?: () => void;
      }
    | {
        /**
         * A function to perform when the user closes the modal.  Should remove the
         * modal from the DOM, but can be used to prevent the modal from closing (for
         * example, to prevent losing a form without confirmation from the user).
         *
         * Optional for marketplace modal.  By default, will dispatch the action to
         * hide the modal. Cannot be used with onAfterClose.
         *
         * @example
         * ```typescript
         * {
         *   // Prevent the modal from closing unless the user confirms they
         *   // want to lose their progress
         *   onClose: () => {
         *      if (window.confirm('Warning: You will lose your changes if you exit.')) {
         *        dispatch(hideModal());
         *      }
         *   }
         * }
         * ```
         */
        onClose?: () => void;
      }
  );

interface HideModalProps {
  hideModalFn?: () => void;
}

interface ModalState {
  isVisible: boolean;
  modalType?: PredefinedModalV2;
  modalContent?: ((props: any) => JSX.Element) | null;
  modalProps?: Record<string, any>;
  modalOptions?: ModalOptions;
  version: 2 | 1 | null;
  modalV2: {
    modalProps: ModalV2Props;
    contentProps: Record<string, any>;
  } | null;
}

export type ShowV2PredefinedModalType = <ContentPropsType = never>(
  modalType: PredefinedModalV2,
  modalProps: ModalV2Props,
  contentProps: Omit<ContentPropsType, 'hideModalFn'>
) => void;

export type UpdateV2ModalPropsType = (modalV2Props: Partial<ModalV2Props>) => void;

export type ShowCustomModalType = <T>(
  modalContent: (props: T & HideModalProps) => JSX.Element,
  modalProps?: ModalPropsType<T & HideModalProps>,
  modalOptions?: ModalOptions
) => void;

export type ShowV2CustomModalType = <ContentPropsType = never>(
  modalContent: (props: ContentPropsType) => JSX.Element,
  modalProps: ModalV2Props,
  contentProps: Omit<ContentPropsType, 'hideModalFn'>
) => void;

export type ShowAuthModalType = <ContentPropsType>(
  modalType: PredefinedModalV2,
  contentProps?: Omit<ContentPropsType, 'hideModalFn'>
) => void;

const initialModalState: ModalState = {
  isVisible: false,
  modalType: undefined,
  modalContent: null,
  modalProps: {},
  modalOptions: {},
  version: null,
  modalV2: null,
};

const ModalContext = createContext<{
  modalState: ModalState;
  showV2PredefinedModal: ShowV2PredefinedModalType;
  updateV2ModalProps: UpdateV2ModalPropsType;
  showCustomModal: ShowCustomModalType;
  showV2CustomModal: ShowV2CustomModalType;
  showAuthModal: ShowAuthModalType;
  hideModal: () => void;
  isModalVisible: boolean;
}>({
  modalState: initialModalState,
  showV2PredefinedModal: () => undefined,
  updateV2ModalProps: () => undefined,
  showCustomModal: () => undefined,
  showV2CustomModal: () => undefined,
  showAuthModal: () => undefined,
  hideModal: () => undefined,
  isModalVisible: false,
});

const showV2PredefinedModalAction = createAction<{
  modalType: PredefinedModalV2;
  modalV2: {
    modalProps: ModalV2Props;
    contentProps: any;
  };
}>('SHOW_PREDEFINED_MODAL_V2');

const updateModalV2PropsAction = createAction<{
  modalV2Props: Partial<ModalV2Props>;
}>('UPDATE_MODAL_V2_PROPS');

const showCustomModalAction = createAction<{
  modalContent: (props: any) => JSX.Element;
  modalProps?: ModalPropsType<any>;
  modalOptions?: ModalOptions;
}>('SHOW_CUSTOM_MODAL');

const showV2CustomModalAction = createAction<{
  modalContent: (props: any) => JSX.Element;
  modalV2: {
    modalProps: ModalV2Props;
    contentProps: any;
  };
}>('SHOW_CUSTOM_MODAL_V2');

const hideModalAction = createAction('HIDE_MODAL');

const modalReducer = createReducer<ModalState>(initialModalState, (builder) => {
  builder
    .addCase(showV2PredefinedModalAction, (state, action) => {
      return {
        ...state,
        ...action.payload,
        version: 2,
        isVisible: true,
        modalV2: {
          ...action.payload.modalV2,
          modalProps: {
            ...action.payload.modalV2.modalProps,
            triggerRef: null,
          },
        },
      };
    })
    .addCase(updateModalV2PropsAction, (state, action) => {
      let modalV2 = null;
      if (state.modalV2 !== null) {
        modalV2 = {
          ...state.modalV2,
          modalProps: {
            ...state.modalV2.modalProps,
            ...action.payload.modalV2Props,
            triggerRef: null,
          },
        };
      }
      return {
        ...state,
        modalV2,
      };
    })
    .addCase(showCustomModalAction, (state, action) => {
      return {
        ...state,
        version: null,
        modalType: undefined,
        ...action.payload,
        isVisible: true,
      };
    })
    .addCase(showV2CustomModalAction, (state, action) => {
      return {
        ...state,
        ...action.payload,
        version: 2,
        isVisible: true,
        modalV2: {
          ...action.payload.modalV2,
          modalProps: {
            ...action.payload.modalV2.modalProps,
            triggerRef: null,
          },
        },
      };
    })
    .addCase(hideModalAction, () => {
      return {
        isVisible: false,
        modalType: undefined,
        modalContent: null,
        modalProps: {},
        modalOptions: {},
        version: null,
        modalV2: null,
      };
    });
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalState, dispatch] = useReducer(modalReducer, initialModalState);

  const showV2PredefinedModal = useCallback(function <ContentPropsType = never>(
    modalType: PredefinedModalV2,
    modalProps: ModalV2Props,
    contentProps: Omit<ContentPropsType, 'hideModalFn'>
  ) {
    dispatch(
      showV2PredefinedModalAction({
        modalType,
        modalV2: {
          modalProps,
          contentProps,
        },
      })
    );
  },
  []);

  const updateV2ModalProps = useCallback((modalV2Props: Partial<ModalV2Props>) => {
    dispatch(
      updateModalV2PropsAction({
        modalV2Props,
      })
    );
  }, []);

  const showCustomModal = useCallback(function <T>(
    modalContent: (props: T & HideModalProps) => JSX.Element,
    modalProps?: ModalPropsType<T & HideModalProps>,
    modalOptions?: ModalOptions
  ) {
    dispatch(showCustomModalAction({ modalContent, modalProps, modalOptions }));
  },
  []);

  const showV2CustomModal = useCallback(function <ContentPropsType = never>(
    modalContent: (props: ContentPropsType) => JSX.Element,
    modalProps: ModalV2Props,
    contentProps: Omit<ContentPropsType, 'hideModalFn'>
  ) {
    dispatch(
      showV2CustomModalAction({
        modalContent,
        modalV2: {
          modalProps,
          contentProps,
        },
      })
    );
  },
  []);

  const showAuthModal = useCallback(
    function <ContentPropsType>(
      modalType: PredefinedModalV2,
      contentProps?: Omit<ContentPropsType, 'hideModalFn'>
    ) {
      let dialogHeadingId;

      switch (modalType) {
        case 'VENDOR_FORGOT_PASSWORD':
          dialogHeadingId = 'vendor-forgot-password-modal';
          break;
        case 'VENDOR_LOGIN':
          dialogHeadingId = 'vendor-login-modal';
          break;
        case 'VENDOR_SIGNUP':
          dialogHeadingId = 'vendor-signup-modal';
          break;
        default:
          dialogHeadingId = '';
      }

      showV2PredefinedModal(
        modalType,
        {
          closeOnEscape: false,
          closeOnOverlayClick: false,
          dialogHeadingId,
          triggerRef: null,
        },
        { ...(contentProps || {}) }
      );
    },
    [showV2PredefinedModal]
  );

  const hideModal = useCallback(() => {
    dispatch(hideModalAction());
  }, []);

  return (
    <ModalContext.Provider
      value={{
        modalState,
        showV2PredefinedModal,
        updateV2ModalProps,
        showCustomModal,
        showV2CustomModal,
        showAuthModal,
        hideModal,
        isModalVisible: modalState.isVisible,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
